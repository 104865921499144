// GISService.js
/* eslint-disable */
import {
    BaseAPI
} from './BaseAPI';

export class GISService extends BaseAPI {
    constructor(config) {
        super(config);
    }
    
    //

    async search(name) {
        return [
            {
                matched:"Test",
                type:"River", // can either me catchment / subcatchment, monitoring point or river
                coordinates:[] // gis coordinate array
            },
            {
                matched:"Test",
                type:"Catchment", // can either me catchment / subcatchment monitoring point or river
                coordinates:[] // gis coordinate array
            }
        ]
        
    }



    // Call this when catchments toggled on the UI
    async getCatchments() {
        const query =`
            PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
            PREFIX fwgw: <http://www.freshwatergateway.org.uk/vocab/>
            PREFIX schema: <https://schema.org/>
            PREFIX dct: <http://purl.org/dc/terms/>

            select ?title ?geo where {
                ?s rdf:type fwgw:Catchment .
                ?s dct:title ?title .
                ?s schema:geo ?b .
                ?b schema:polygon ?geo .
            }
        `;

        var response = await this.query(query);
        return response;
    }

    async getRivers() {
        const query =`
            PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
            PREFIX fwgw: <http://www.freshwatergateway.org.uk/vocab/>
            PREFIX schema: <https://schema.org/>
            PREFIX dct: <http://purl.org/dc/terms/>

            select ?title ?geo where {
                ?s rdf:type schema:RiverBodyOfWater .
                ?s dct:title ?title .
                ?s schema:geo ?b .
                ?b schema:line ?geo .
            }
        `;

        var response = await this.query(query);
        return response;
    }

   async getSubCatchments() {
    const query =`
        PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
        PREFIX fwgw: <http://www.freshwatergateway.org.uk/vocab/>
        PREFIX schema: <https://schema.org/>
        PREFIX dct: <http://purl.org/dc/terms/>

        select ?title ?geo where {
            ?s rdf:type fwgw:SubCatchment .
            ?s dct:title ?title .
            ?s schema:geo ?b .
            ?b schema:polygon ?geo .
        }
    `;

    var response = await this.query(query);
    return response;
    }

}