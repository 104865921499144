<template>
  <div class="search-container">
    <v-text-field
      v-model="searchQuery"
      prepend-inner-icon="mdi-magnify"
      label="Search for locations in Wales"
      solo
      dense
      hide-details
      @input="filterSuggestions"
      @focus="showSuggestions = true"
      @blur="hideSuggestionsDelayed"
      @keyup.enter="handleEnter"
    ></v-text-field>
    <v-list v-if="showSuggestions && filteredSuggestions.length" class="suggestions-list">
      <v-list-item
        v-for="suggestion in filteredSuggestions"
        :key="suggestion.title"
        @mousedown.prevent="selectSuggestion(suggestion)"
      >
        <v-list-item-title>{{ suggestion.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { ref, computed } from 'vue';

export default {
  name: "MapSearchBar",
  props: {
    suggestions: {
      type: Array,
      default: () => []
    }
  },
  setup(props, { emit }) {
    const searchQuery = ref('');
    const showSuggestions = ref(false);

    const filteredSuggestions = computed(() => {
      if (searchQuery.value.length < 2) return [];
      return props.suggestions.filter(suggestion =>
        suggestion.title.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
    });

    const filterSuggestions = () => {
      showSuggestions.value = true;
      emit('search', searchQuery.value);
    };

    const selectSuggestion = (suggestion) => {
      searchQuery.value = suggestion.title;
      showSuggestions.value = false;
      emit('selected', suggestion);
    };

    const hideSuggestionsDelayed = () => {
      setTimeout(() => {
        showSuggestions.value = false;
      }, 200);
    };

    const handleEnter = () => {
      if (filteredSuggestions.value.length > 0) {
        emit('multipleResults', filteredSuggestions.value);
      }
    };

    return {
      searchQuery,
      showSuggestions,
      filteredSuggestions,
      filterSuggestions,
      selectSuggestion,
      hideSuggestionsDelayed,
      handleEnter
    };
  }
};
</script>

<style scoped>
.search-container {
  position: relative;
  z-index: 1000;
  width: 100%;
}

.suggestions-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 200px;
  overflow-y: auto;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

/* You might need to adjust these styles based on your Vuetify setup */
:deep(.v-text-field) {
  margin-top: 0;
  margin-bottom: 0;
}
</style>