/* eslint-disable */
import axios from 'axios';

export class BaseAPI {
  constructor() {
  }

  async query(query) {
    var response = axios.get('https://graphdb.freshwatergateway.org.uk/repositories/freshwatergateway?query='+encodeURIComponent(query), {
      headers: { 'ACCEPT': 'application/sparql-results+json'}
    });
    var data = (await response).data.results.bindings;
    for (let i=0; i < data.length;i++) {
      for (var key in data[i]) {
        if (key=="geo") {
          const split1=data[i][key].value.split(" ");
          for (let x=0; x < split1.length;x++) split1[x]=split1[x].split(",");
          data[i][key] = split1;
        } else {
          data[i][key]=data[i][key].value;
        }
      }
    }
    return data;
  }
}
