<template>
  <v-card class="time-intervals-card">
      <div class="section-title">Time Intervals</div>
      <v-divider></v-divider>
      <div class="time-intervals">
        <v-row v-for="(interval, index) in intervals" :key="index" class="mb-2">
          <v-col cols="5">
            <v-text-field
              v-model="interval.startDate"
              label="Start Date"
              type="date"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="1" class="d-flex align-center justify-center">
            <span>to</span>
          </v-col>
          <v-col cols="5">
            <v-text-field
              v-model="interval.endDate"
              label="End Date"
              type="date"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="1" class="d-flex align-center">
            <v-btn icon color="error" @click="removeInterval(index)">
              <v-icon>mdi-minus-circle</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-btn
          color="primary"
          @click="addInterval"
          class="mt-2"
          prepend-icon="mdi-plus"
        >
          Add Interval
        </v-btn>
      </div>
  </v-card>
</template>

<script>
import { ref } from 'vue';

export default {
  setup() {
    const intervals = ref([{ startDate: '', endDate: '' }]);

    const addInterval = () => {
      intervals.value.push({ startDate: '', endDate: '' });
    };

    const removeInterval = (index) => {
      intervals.value.splice(index, 1);
    };

    return {
      intervals,
      addInterval,
      removeInterval,
    };
  },
};
</script>

<style scoped>
.time-intervals-card {
  padding: 16px;
  background-color: #f0f4fa;
  border-radius: 8px;
  box-shadow: none;
  border: 1px solid #d0d7e1;
}

.section-title {
  font-weight: bold;
  font-size: 16px;
  color: #333;
  margin-bottom: 8px;
}
</style>