<template>
  <v-app-bar app color="primary" dark>
    <v-toolbar-title>Freshwater Gateway Project</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn text @click="$router.push('/')">Home</v-btn>
    <v-btn text @click="$router.push('/graph')">Graph</v-btn>
    <v-btn text>About</v-btn>
    <v-btn text>Contact</v-btn>
  </v-app-bar>
</template>

<script>
export default {
  name: "HeaderBar",
};
</script>

<style scoped>
.v-app-bar {
  background-color: #2c82de;
}
.v-btn {
  color: white;
}
</style>