<script setup>
/* eslint-disable */
import { provide } from 'vue';
// import {ParameterService} from './api/';
import {GISService} from './api/';
// import {DataService} from './api/';

// provide('ParameterService',new ParameterService());
// provide('DataService',new DataService());
provide('GISService',new GISService());
</script>

<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
}
</script>