<template>
  <div class="bg-white p-4 rounded-lg shadow-md">
    <h3 class="text-lg font-semibold mb-4">Filter by...</h3>
    <SampleType />
    <SuitabilityIndicators />
    <SamplingApproach />
    <TimeIntervals />
    <DataAggregation />
  </div>
</template>

<script>
import SampleType from './SampleType.vue';
import SuitabilityIndicators from './SuitabilityIndicators.vue';
import SamplingApproach from './SamplingApproach.vue';
import TimeIntervals from './TimeIntervals.vue';
import DataAggregation from './DataAggregation.vue';

export default {
  components: {
    SampleType,
    SuitabilityIndicators,
    SamplingApproach,
    TimeIntervals,
    DataAggregation
  }
};
</script>