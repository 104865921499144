<template>
  <div class="bg-white p-4 rounded-lg shadow-md">
    <FormatOptions />
    <FilterBy />
  </div>
</template>

<script>
import FormatOptions from './FormatOptions.vue';
import FilterBy from './FilterBy.vue';

export default {
  components: {
    FormatOptions,
    FilterBy
  }
};
</script>

<style scoped>
/* Sidebar Styling */

.sidebar {
  background-color: #e3f2fd; /* Light blue background */
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sidebar h3 {
  color: #1976d2; /* Darker blue for headings */
  margin-bottom: 8px;
}

.sidebar .section {
  margin-bottom: 16px;
}

.sidebar .section label {
  display: block;
  margin-bottom: 4px;
  color: #424242; /* Dark gray for text */
}

.sidebar .section input {
  margin-right: 8px;
}
</style>