<template>
  <div class="map-container">
    <div class="search-overlay">
      <MapSearchBar 
        :suggestions="catchments" 
        @search="handleSearch" 
        @selected="handleSuggestionSelected"
        @multipleResults="handleMultipleResults"
      />
    </div>
    <div id="map" class="map"></div>
  </div>
</template>

<script>
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import MapSearchBar from './MapSearchBar.vue';
import { ref, onMounted, watch } from 'vue';

export default {
  name: 'MapComponent',
  components: {
    MapSearchBar,
  },
  props: {
    initialResults: Array,
    catchments: Array,
  },
  setup(props, { emit }) {
    const map = ref(null);
    const results = ref(props.initialResults);
    const filteredResults = ref(props.initialResults);

    const WALES_CENTER = [-3.7, 52.5];
    const DEFAULT_ZOOM = 7.2;

    onMounted(() => {
      initializeMap();
    });

    watch(() => props.catchments, (newCatchments) => {
      if (newCatchments && newCatchments.length > 0 && map.value) {
        processCatchments(newCatchments);
      }
    });

    const initializeMap = () => {
      console.log('Initializing map');
      mapboxgl.accessToken = 'pk.eyJ1IjoiYW5kcmVpaDE5NCIsImEiOiJjbGdob25kbDAwbnB6M2l0MWpxNnBvczFvIn0.Z8UWHTSl7KxL56wrT4APXQ';

      map.value = new mapboxgl.Map({
        container: 'map',
        // style: 'mapbox://styles/mapbox/light-v10',
        center: WALES_CENTER,
        zoom: DEFAULT_ZOOM,
        // minZoom: 6,
        // maxBounds: [[-5.5, 51.3], [-2.5, 53.5]]
      });

      const nav = new mapboxgl.NavigationControl({ showCompass: false });
      map.value.addControl(nav, 'top-left');

      class ResetControl {
        onAdd(map) {
          this._map = map;
          this._container = document.createElement('div');
          this._container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
          this._container.innerHTML = '<button class="mapboxgl-ctrl-icon" type="button" title="Reset map"><span class="mapboxgl-ctrl-icon" aria-hidden="true">🔄</span></button>';
          this._container.onclick = () => {
            map.flyTo({ center: WALES_CENTER, zoom: DEFAULT_ZOOM });
            removeHighlights();
          };
          return this._container;
        }

        onRemove() {
          this._container.parentNode.removeChild(this._container);
          this._map = undefined;
        }
      }

      map.value.addControl(new ResetControl(), 'top-left');

      map.value.on('load', () => {
        console.log('Map loaded');
        addWalesBoundary();
        if (props.catchments && props.catchments.length > 0) {
          processCatchments(props.catchments);
        }
      });
    };

    const addWalesBoundary = () => {
      if (!map.value.getSource('wales-boundary')) {
        map.value.addSource('wales-boundary', {
          type: 'geojson',
          data: 'https://raw.githubusercontent.com/martinjc/UK-GeoJSON/master/json/administrative/wa/topo_wal.json'
        });

        map.value.addLayer({
          id: 'wales-boundary-layer',
          type: 'line',
          source: 'wales-boundary',
          paint: {
            'line-color': '#0b5345',
            'line-width': 2
          }
        });

        map.value.addLayer({
          id: 'wales-fill-layer',
          type: 'fill',
          source: 'wales-boundary',
          paint: {
            'fill-color': '#66cdaa',
            'fill-opacity': 0.3
          }
        });
      }
    };

    const removeHighlights = () => {
      if (map.value.getLayer('highlight-layer')) {
        map.value.removeLayer('highlight-layer');
      }
      if (map.value.getSource('highlight-source')) {
        map.value.removeSource('highlight-source');
      }
    };

    const processCatchments = (catchments) => {
      console.log('Processing catchments');
      try {
        const features = catchments.map(catchment => {
          try {
            console.log('Processing catchment:', catchment.title);
            const coordinates = processGeoData(catchment.geo);
            return {
              type: 'Feature',
              properties: { title: catchment.title },
              geometry: {
                type: 'Polygon',
                coordinates: [coordinates]
              }
            };
          } catch (error) {
            console.error(`Error processing catchment "${catchment.title}":`, error.message);
            console.log('Problematic geo data:', catchment.geo);
            return null;
          }
        }).filter(feature => feature !== null);

        console.log('Processed features:', features);

        if (features.length > 0) {
          addCatchmentsToMap(features);
        } else {
          console.warn('No valid features to add to the map');
        }
      } catch (error) {
        console.error('Error processing catchments:', error);
      }
    };

    const processGeoData = (geoData) => {
      console.log('Processing geo data:', geoData);

      if (Array.isArray(geoData)) {
        if (geoData.length === 0) {
          throw new Error('Empty geo data array');
        }

        if (geoData.every(item => typeof item === 'string')) {
          return chunkArray(geoData, 2).map(([lat, lng]) => {
            const [parsedLng, parsedLat] = [parseFloat(lng), parseFloat(lat)];
            if (isNaN(parsedLat) || isNaN(parsedLng)) {
              throw new Error(`Invalid coordinate: ${lat},${lng}`);
            }
            return [parsedLng, parsedLat];
          });
        } else if (Array.isArray(geoData[0])) {
          return geoData.map((coord, index) => {
            if (!Array.isArray(coord) || coord.length !== 2) {
              throw new Error(`Invalid coordinate at index ${index}: expected array of length 2, got ${JSON.stringify(coord)}`);
            }
            const [lat, lng] = coord.map(Number);
            if (isNaN(lat) || isNaN(lng)) {
              throw new Error(`Invalid coordinate at index ${index}: expected two numbers, got ${JSON.stringify(coord)}`);
            }
            return [lng, lat];
          });
        }
      } else if (typeof geoData === 'string') {
        return geoData.split(' ').map(coord => {
          const [lat, lng] = coord.split(',').map(Number);
          if (isNaN(lat) || isNaN(lng)) {
            throw new Error('Invalid coordinate in string');
          }
          return [lng, lat];
        });
      }

      throw new Error(`Unsupported geo data format: ${typeof geoData}`);
    };

    const chunkArray = (arr, chunkSize) => {
      const chunks = [];
      for (let i = 0; i < arr.length; i += chunkSize) {
        chunks.push(arr.slice(i, i + chunkSize));
      }
      return chunks;
    };

    const addCatchmentsToMap = (features) => {
      console.log('Adding catchments to map');
      if (map.value && !map.value.getSource('catchments')) {
        map.value.addSource('catchments', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: features
          }
        });

        map.value.addLayer({
          id: 'catchments-layer',
          type: 'fill',
          source: 'catchments',
          paint: {
            'fill-color': '#088',
            'fill-opacity': 0.3
          }
        });

        map.value.addLayer({
          id: 'catchments-outline',
          type: 'line',
          source: 'catchments',
          paint: {
            'line-color': '#088',
            'line-width': 2
          }
        });
      }
    };

    const handleSuggestionSelected = (suggestion) => {
      console.log('Selected suggestion:', suggestion);
      if (suggestion && suggestion.geo) {
        focusOnShape(suggestion);
      }
    };

    const focusOnShape = (suggestion) => {
      let coordinates;
      try {
        coordinates = processGeoData(suggestion.geo);
      } catch (error) {
        console.error('Error processing selected shape:', error);
        return;
      }

      removeHighlights();

      map.value.addSource('highlight-source', {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: coordinates
          }
        }
      });

      map.value.addLayer({
        id: 'highlight-layer',
        type: 'line',
        source: 'highlight-source',
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': '#FF0000',
          'line-width': 3
        }
      });

      const bounds = coordinates.reduce(
        (bounds, coord) => bounds.extend(coord),
        new mapboxgl.LngLatBounds(coordinates[0], coordinates[0])
      );
      map.value.fitBounds(bounds, { padding: 50 });
    };

    const handleSearch = (query) => {
      filteredResults.value = results.value.filter((result) =>
        result.title.toLowerCase().includes(query.toLowerCase())
      );
      emit('filteredResults', filteredResults.value);
    };

    const handleMultipleResults = (results) => {
      console.log('Multiple results:', results);
      const mockResults = results.map((result, index) => ({
        id: index + 1,
        title: result.title,
        location: 'Location XYZ',
        type: ['river', 'catchment', 'samplingPoint'][Math.floor(Math.random() * 3)],
        dataset: ['eDNA sampling', 'Freshwater Fish Species'][Math.floor(Math.random() * 2)],
        dataFrom: 'NRW'
      }));
      filteredResults.value = mockResults;
      emit('filteredResults', mockResults);
    };

    return {
      results,
      filteredResults,
      handleSuggestionSelected,
      handleSearch,
      handleMultipleResults
    };
  }
};
</script>

<style scoped>
.map-container {
  position: relative;
  width: 100%;
  height: 100vh;
}

.search-overlay {
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  z-index: 1;
  pointer-events: none;
}

.search-overlay :deep(.search-container) {
  pointer-events: auto;
  width: 80%;
  max-width: 400px;
  margin: 0 auto;
}

.map {
  width: 100%;
  height: 100%;
}

.mapboxgl-ctrl-top-left {
  margin-top: 70px;
}

.mapboxgl-ctrl-icon {
  font-size: 20px;
  line-height: 30px;
}
</style>