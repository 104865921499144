<template>
  <div class="search-results-container" :class="{ 'collapsed': isCollapsed }">
    <div class="search-results-header">
      <h2 class="search-results-title" :class="{ 'hidden': isCollapsed }">Search results</h2>
      <div class="toggle-button" @click="toggleCollapse">
        <v-icon>{{ isCollapsed ? 'mdi-chevron-left' : 'mdi-chevron-right' }}</v-icon>
      </div>
    </div>
    <div class="search-results-content" v-show="!isCollapsed">
      <div v-for="result in results" :key="result.id" class="result-card">
        <div class="result-header">
          <div class="icon-container" :class="result.type">
            <v-icon class="icon">{{ getIcon(result.type) }}</v-icon>
          </div>
          <div class="result-info">
            <h4 class="result-title">{{ result.title }}</h4>
            <p class="result-location">{{ result.location }}</p>
          </div>
        </div>
        <div class="result-details">
          <div class="detail-item">
            <v-icon class="detail-icon fish-icon">mdi-fish</v-icon>
            <span class="detail-text">Dataset: {{ result.dataset }}</span>
          </div>
          <div class="detail-item">
            <v-icon class="detail-icon data-icon">mdi-database</v-icon>
            <span class="detail-text">Data from: {{ result.dataFrom }}</span>
          </div>
        </div>
        <div class="result-buttons">
          <button @click="$emit('see-graph', result.id)" class="action-btn see-graph-btn">
            <v-icon class="button-icon">mdi-chart-line</v-icon>
            See Graph
          </button>
          <button @click="$emit('see-metadata', result.id)" class="action-btn see-metadata-btn">
            <v-icon class="button-icon">mdi-information-outline</v-icon>
            See Metadata
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchResults',
  props: {
    results: {
      type: Array,
      default: () => []
    },
    isCollapsed: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getIcon(type) {
      const icons = {
        river: 'mdi-waves',
        catchment: 'mdi-map',
        samplingPoint: 'mdi-map-marker',
      };
      return icons[type] || 'mdi-help-circle';
    },
    toggleCollapse() {
      this.$emit('toggle-collapse', !this.isCollapsed);
    },
  },
};
</script>

<style scoped>
.search-results-container {
  position: absolute;
  top: 80px;
  right: 0;
  background-color: #f0f4f8;
  font-family: Arial, sans-serif;
  transition: all 0.3s ease;
  width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
}

.search-results-container.collapsed {
  width: 60px;
}

.search-results-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #4a90e2;
  padding: 12px 16px;
  color: #ffffff;
  height: 60px;
}

.search-results-title {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: opacity 0.3s ease;
}

.search-results-title.hidden {
  opacity: 0;
}

.toggle-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  transition: background-color 0.2s;
}

.toggle-button:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.search-results-content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 16px;
}

.result-card {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.result-header {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.icon-container {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
}

.icon-container.river {
  background-color: #e3f2fd;
}

.icon-container.catchment {
  background-color: #e8f5e9;
}

.icon-container.samplingPoint {
  background-color: #e74c3c;
}

.icon {
  font-size: 20px;
}

.river .icon {
  color: #1976d2;
}

.catchment .icon {
  color: #43a047;
}

.samplingPoint .icon {
  color: #ffffff;
}

.result-info {
  flex-grow: 1;
}

.result-title {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  color: #2d3748;
}

.result-location {
  font-size: 14px;
  color: #718096;
  margin: 2px 0 0 0;
}

.result-details {
  margin-bottom: 12px;
}

.detail-item {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.detail-icon {
  font-size: 18px;
  margin-right: 8px;
}

.fish-icon {
  color: #3498db;
}

.data-icon {
  color: #9b59b6;
}

.detail-text {
  font-size: 14px;
  color: #4a5568;
}

.result-buttons {
  display: flex;
  justify-content: space-between;
}

.action-btn {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.see-graph-btn {
  background-color: #3498db;
  color: #ffffff;
  margin-right: 8px;
}

.see-metadata-btn {
  background-color: #e2e8f0;
  color: #4a5568;
}

.see-graph-btn:hover {
  background-color: #2980b9;
}

.see-metadata-btn:hover {
  background-color: #cbd5e0;
}

.button-icon {
  margin-right: 6px;
  font-size: 18px;
}
</style>