<template>
  <v-card class="sample-type-card">
    <div class="section-title">Sample type</div>
    <v-divider></v-divider>

    <div class="option-grid">
      <template v-for="(option, index) in sampleOptions" :key="index">
        <div :class="['option-row', { 'parent-option': option.children.length > 0 }]">
          <v-switch
            v-model="option.enabled"
            @change="toggleParentOption(option)"
            class="custom-switch"
            inset
            hide-details
            color="success"
          />
          <div 
            :class="['option-label', { 'active-label': option.enabled }]" 
            @click="toggleExpand(option)"
          >
            {{ option.label }}
            <v-icon v-if="option.children.length > 0" small class="expand-icon">
              {{ option.expanded ? 'mdi-chevron-down' : 'mdi-chevron-right' }}
            </v-icon>
          </div>
        </div>

        <template v-if="option.children.length > 0 && option.expanded">
          <div v-for="(child, childIndex) in option.children" :key="`${index}-${childIndex}`" class="option-row child-option">
            <v-switch
              v-model="child.enabled"
              @change="toggleChildOption(option)"
              class="custom-switch child-switch"
              inset
              hide-details
              color="success"
            />
            <div :class="['option-label child-label', { 'active-label': child.enabled }]">
              {{ child.label }}
            </div>
          </div>
        </template>
      </template>
    </div>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      sampleOptions: [
        { label: "Sediment/soil", enabled: false, children: [], expanded: false },
        { label: "Water", enabled: false, children: [], expanded: false },
        {
          label: "Biota",
          enabled: false,
          expanded: true,
          children: [
            { label: "macroinvertebrate diversity", enabled: false },
            { label: "otter survey", enabled: false },
            { label: "algae levels", enabled: false }
          ]
        },
        {
          label: "fish survey",
          enabled: true,
          expanded: true,
          children: [
            { label: "electrofishing", enabled: true },
            { label: "eDNA sampling", enabled: true },
            { label: "hydroacustics", enabled: true }
          ]
        }
      ]
    };
  },
  methods: {
    toggleExpand(option) {
      if (option.children.length > 0) {
        option.expanded = !option.expanded;
      }
    },
    toggleParentOption(option) {
      option.children.forEach(child => {
        child.enabled = option.enabled;
      });
    },
    toggleChildOption(parent) {
      parent.enabled = parent.children.every(child => child.enabled);
    }
  }
};
</script>

<style scoped>
.sample-type-card {
  padding: 16px;
  background-color: #f0f4fa;
  border-radius: 8px;
  box-shadow: none;
  border: 1px solid #d0d7e1;
}

.section-title {
  font-weight: bold;
  font-size: 16px;
  color: #333;
  margin-bottom: 8px;
}

.option-grid {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px 16px;
  align-items: center;
}

.option-row {
  display: contents;
}

.parent-option {
  margin-top: 8px;
}

.child-option {
  grid-column: 1 / span 2;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
}

.child-switch {
  margin-left: 24px;
}

.child-label {
  padding-left: 16px;
}

.option-label {
  font-size: 14px;
  color: #666;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.active-label {
  font-weight: bold;
  color: #000;
}

.custom-switch {
  margin-right: 0;
}

.expand-icon {
  margin-left: 4px;
}

/* Custom styles for v-switch */
:deep(.v-input--switch) {
  margin-top: 0;
  padding-top: 0;
}

:deep(.v-input--switch .v-input__slot) {
  margin-bottom: 0;
}

:deep(.v-input--switch__track) {
  opacity: 1 !important;
  background-color: #bdbdbd !important;
  border-color: #bdbdbd !important;
}

:deep(.v-input--switch.v-input--is-dirty .v-input--switch__track) {
  background-color: #4caf50 !important;
  border-color: #4caf50 !important;
}

:deep(.v-input--switch__thumb) {
  color: white !important;
}

:deep(.v-input--switch.v-input--is-dirty .v-input--switch__thumb) {
  transform: translate(16px, 0);
}
</style>