<template>
  <v-app>
    <HeaderBar />
    <v-main>
      <v-container fluid class="pa-0 ma-0 main-container">
        <v-row no-gutters class="fill-height">
          <v-col cols="3" class="left-sidebar">
            <v-sheet rounded="lg" min-height="268" class="fill-height">
              <LeftAppSidebar />
            </v-sheet>
          </v-col>

          <v-col :cols="mapColSpan" class="map-container">
            <MapComponent
              :initialResults="results"
              :catchments="catchments"
              @filteredResults="updateFilteredResults"
              ref="mapComponent"
            />
          </v-col>

          <v-col :cols="sidebarColSpan" class="search-results-col">
            <SearchResults
              :results="filteredResults"
              @toggle-sidebar="toggleSidebar"
              :isCollapsed="isSidebarCollapsed"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { ref, computed, onMounted, inject } from 'vue';
import HeaderBar from './HeaderBar.vue';
import LeftAppSidebar from './LeftAppSidebar.vue';
import SearchResults from './SearchResults.vue';
import MapComponent from './MapComponent.vue';

export default {
  name: 'HomeView',
  components: {
    HeaderBar,
    LeftAppSidebar,
    SearchResults,
    MapComponent,
  },
  setup() {
    const GISService = inject('GISService');
    const results = ref([]);
    const filteredResults = ref([]);
    const isSidebarCollapsed = ref(false);
    const catchments = ref([]);

    const mapColSpan = computed(() => isSidebarCollapsed.value ? 8 : 6);
    const sidebarColSpan = computed(() => isSidebarCollapsed.value ? 1 : 3);

    onMounted(async () => {
      if (GISService) {
        try {
          catchments.value = await GISService.getCatchments();
          console.log('Fetched catchments:', catchments.value);
        } catch (error) {
          console.error('Error fetching catchments:', error);
        }
      } else {
        console.error('GISService is not properly injected');
      }
    });

    const updateFilteredResults = (newFilteredResults) => {
      filteredResults.value = newFilteredResults;
    };

    const toggleSidebar = () => {
      isSidebarCollapsed.value = !isSidebarCollapsed.value;
    };

    return {
      results,
      filteredResults,
      isSidebarCollapsed,
      catchments,
      mapColSpan,
      sidebarColSpan,
      updateFilteredResults,
      toggleSidebar,
    };
  },
};
</script>

<style scoped>
.main-container {
  height: calc(100vh - 64px);
}

.fill-height {
  height: 100%;
}

.map-container, .search-results-col {
  transition: all 0.3s ease;
}

.map-container > * {
  width: 100%;
  height: 100%;
}
</style>