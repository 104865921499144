import { createApp } from 'vue'
import App from './App.vue'
// import { GISService } from './api/'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import 'mapbox-gl/dist/mapbox-gl.css'
// import mapboxgl from 'mapbox-gl'


import 'vuetify/styles'
// Import Material Design Icons
import '@mdi/font/css/materialdesignicons.css'

import router from './router';

loadFonts()

createApp(App)
  .use(vuetify)
  .use(router)
  .mount('#app')

// app.config.globalProperties.$mapboxgl = mapboxgl