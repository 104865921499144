<template>
  <v-card class="sampling-approach-card">
    <div class="section-title">Sampling approach</div>
    <v-divider></v-divider>
    <div class="option-grid">
      <template v-for="(option, key) in samplingOptions" :key="key">
        <div class="option-row">
          <v-switch
            v-model="option.enabled"
            @change="toggleOption(key)"
            class="custom-switch"
            inset
            hide-details
            color="success"
          />
          <div 
            :class="['option-label', { 'active-label': option.enabled }]"
          >
            {{ option.label }}
          </div>
        </div>
      </template>
    </div>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      samplingOptions: {
        qualitative: { label: "Qualitative", enabled: true },
        quantitativeManual: { label: "Quantitative manual", enabled: false },
        quantitativeAutomatic: { label: "Quantitative automatic", enabled: false }
      }
    };
  },
  methods: {
    toggleOption(key) {
      console.log(`${this.samplingOptions[key].label} is now ${this.samplingOptions[key].enabled ? "enabled" : "disabled"}`);
    }
  }
};
</script>

<style scoped>
.sampling-approach-card {
  padding: 16px;
  background-color: #f0f4fa;
  border-radius: 8px;
  box-shadow: none;
  border: 1px solid #d0d7e1;
}

.section-title {
  font-weight: bold;
  font-size: 16px;
  color: #333;
  margin-bottom: 8px;
}

.option-grid {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px 16px;
  align-items: center;
}

.option-row {
  display: contents;
}

.option-label {
  font-size: 14px;
  color: #666;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.active-label {
  font-weight: bold;
  color: #000;
}

.custom-switch {
  margin-right: 0;
}

/* Custom styles for v-switch */
:deep(.v-input--switch) {
  margin-top: 0;
  padding-top: 0;
}

:deep(.v-input--switch .v-input__slot) {
  margin-bottom: 0;
}

:deep(.v-input--switch__track) {
  opacity: 1 !important;
  background-color: #bdbdbd !important;
  border-color: #bdbdbd !important;
}

:deep(.v-input--switch.v-input--is-dirty .v-input--switch__track) {
  background-color: #4caf50 !important;
  border-color: #4caf50 !important;
}

:deep(.v-input--switch__thumb) {
  color: white !important;
}

:deep(.v-input--switch.v-input--is-dirty .v-input--switch__thumb) {
  transform: translate(16px, 0);
}
</style>